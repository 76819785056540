.App {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  color: #2D2828;
  position: relative;
}

.visuallyhidden {
  display: none;
}

a {
  text-decoration: none;
  font-weight: 800;
  color: #2D2828;
  letter-spacing: 1px;
}

a:focus {
  outline: 1px solid #2D2828;
}

button {
  transition: all .3s ease-out;
  cursor: pointer;
}
button:focus {
  outline: 0;
}

button:hover,
button:focus {
  background: #E7E4E4;
}

#contacts a:focus {
  outline: 1px solid #fff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.divider {
  border-bottom: 1px solid rgba(124, 124, 124, 0.2);
}

.divider-top {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
}

.color-secondary {
  color: #B2B2B2;
}

section {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 64px;
  padding: 80px 0;
  margin: 0 auto;
  border-right: 1px solid rgba(124, 124, 124, 0.2);
  border-left: 1px solid rgba(124, 124, 124, 0.2);
}

section#works {
  grid-template-rows: auto auto auto;
  row-gap: 80px;
}

section .title {
  grid-column: 1 / 3;
  justify-self: start;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 800;
  text-transform: uppercase;
}

section.portfolio-header,
section#hello {
  padding-top: 40px;
}

.logo {
  grid-column: 1 / 3;
  justify-self: stretch;
  padding: 16px 64px 16px 0px;
  box-shadow: 0px 8px #201D1D;
}

.logo .name {
  font-size: 40px;
  line-height: 48px;
  font-weight: 200;
  margin-bottom: 4px;
  color: #2D2828;
}

.logo .position {
  color: #2D2828;
  letter-spacing: 2px;
}

.navigation {
  grid-column: 4 / 7;
  justify-self: stretch;
  align-self: end;
  padding-bottom: 16px;
}

.navigation ul {
  display: flex;
  justify-content: flex-end;
}

.navigation ul li {
  justify-self: end;
  transition: box-shadow 0.3s ease-out;
  box-shadow: 0px 16px #ffffff, 0px 0px #2D2828;
  margin-left: 64px;
}

.navigation ul li:hover {
  box-shadow: 0px 16px #ffffff, 0px 24px #2D2828;
}

.image-description .link-item a {
  transition: box-shadow 0.3s ease-out;
  box-shadow: 0px 16px #ffffff, 0px 0px #2D2828;
}

.image-description .link-item:hover > a {
  box-shadow: 0px 16px #ffffff, 0px 24px #2D2828;
}

/* HELLO section */

#hello .content-text {
  grid-column: 3 / 7;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
}

.item-bottom {
  align-self: end;
}

/* WORKS section */

.work-text_toggle {
  bottom: 24px;
  position: relative;
  background: #ffffff;
  border: 1px solid #2D2828;
  height: 40px;
  padding: 0 56px 0 16px;
  font-size: 18px;
  font-weight: 800;
  color: #2D2828;
  letter-spacing: 1px;
}

.work-text_toggle::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 40px;
  background: #2D2828 url("./icons/icon-info-light.svg") no-repeat 50% 50%;
}

.work-item {
  position: relative;
}

.work-item-1 {
  grid-column: 3 / 7;
  grid-row: 1 / 2;
}

.work-item-2 {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
}

.work-item-3 {
  grid-column: 4 / 7;
  grid-row: 2 / 3;
}

.work-item-4 {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
}

.work-item-5 {
  grid-column: 5 / 7;
  grid-row: 3 / 4;
}

.image-item {
  max-width: 100%;
}

.image-description {
  position: absolute;
  opacity: 0;
  transition: opacity .5s ease-out;
  transition-delay: .2s;
  pointer-events: none;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.work-item--active .image-description {
  opacity: 1;
  pointer-events: auto;
}

.work-item__actions {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
  padding: 8px 0 24px 0;
}

.row:last-child {
  padding-bottom: 0;
}

.grid-fullwidth {
  grid-column: 1 / 3;
}

.content-title {
  text-transform:uppercase;
  padding-bottom: 8px;
  font-weight: 300;
}

.link-item {
  justify-self: end;
  padding-top: 32px;
}

.icon-item {
  width: 40px;
  justify-self: end;
}

/* ABOUT section */

#about .content-text {
  grid-column: 3 / 7;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 64px;
}

.column-first {
  grid-column: 1 / 2;
}

.column-second {
  grid-column: 2 / 3;
}

.column-first ul li,
.column-second ul li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 8px;
}

.column-first ul li:last-child,
.column-second ul li:last-child {
  margin-bottom: 0;
}

.column-first ul li::before ,
.column-second ul li::before {
  content: '';
  position: absolute;
  display: block;
  top: calc(50% - 3px);
  left: 0;
  width: 6px;
  height: 6px;
  /*border: 1px solid #2D2828;*/
  background: #2D2828;
}

.column-last {
  grid-column: 3 / 5;
}

/* CONTACTS section */

.container {
  background-color: #201D1D;
  /*padding-bottom: 80px;*/
}

#contacts {
  color: #fff;
  padding-bottom: 160px;
}

#contacts .title {
  /*text-shadow: 10px 10px #555555;*/
  letter-spacing: 1px;
}

#contacts .social {
  grid-column: 3 / 5;
}

#contacts .content-text {
  grid-column: 5 / 7;
}

.social-list {
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
}

.social-item img {
  width: 40px;
}

.social-item:not(last-child) {
  margin-right: 40px;
}

.portfolio-header-sticky {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: 0;
  z-index: 1000;
  background: #fff;
  transition: opacity 0s;
}

.portfolio-header-sticky--active {
  transition: opacity .5s .5s ease-out;
  opacity: 1;
  pointer-events: auto;
}

/* Adaptivity */

@media all and (max-width: 1200px) {
  section {
    column-gap: 32px;
    margin: 0 32px;
  }

  section#works {
    row-gap: 40px;
  }

  .divider-top {
    top: 32px;
  }

  section.portfolio-header,
  section#hello {
    padding-top: 20px;
  }

  .logo {
    grid-column: 1 / 3;
    padding: 16px 0px;
    box-shadow: 0px 8px #201D1D;
  }

  .navigation ul li {
    margin-left: 32px;
  }

  #hello .content-text,
  #about .content-text {
    column-gap: 32px;
  }

  .work-item-2,
  .work-item-3,
  .work-item-4 {
    grid-column: 3 / 7;
  }

  .work-item-3 {
    grid-row: 3 / 4;
  }

  .work-item-4 {
    grid-row: 4 / 5;
  }

  .work-item-5 {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
  }
}

@media all and (max-width: 960px) {
  .logo {
    grid-column: 1 / 4;
  }

  #hello .content-text {
    grid-column: 4 / 7;
    column-gap: 32px;
    grid-template-columns: 1fr;
    row-gap: 32px;
  }

  section .title,
  .work-item-1,
  .work-item-2,
  .work-item-2,
  .work-item-3,
  .work-item-4 {
    grid-column: 1 / 7;
  }

  section .title {
    rid-row: 1 / 2;
  }

  .work-item-1 {
    grid-row: 2 / 3;
  }

  .work-item-2 {
    grid-row: 3 / 4;
  }

  .work-item-3 {
    grid-row: 4 / 5;
  }

  .work-item-4 {
    grid-row: 5 / 6;
  }

  .work-item-5 {
    grid-column: 4 / 7;
    grid-row: 6 / 7;
  }

  section#about,
  section#contacts,
  #about .content-text {
    row-gap: 40px;
  }

  #about .content-text {
    grid-column: 1 / 7;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .column-last {
    grid-column: 4 / 7;
    align-self: end;
  }

  #contacts .social {
    grid-column: 1 / 4;
    align-self: end;
  }

  #contacts .content-text {
    grid-column: 4 / 7;
  }
}

@media all and (max-width: 760px) {
  section {
    margin: 0 24px;
  }

  .divider-top {
    top: 24px;
  }

  .logo {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  .navigation {
    grid-column: 1 / 7;
    grid-row: 2 / 3;
  }

  .navigation ul li {
    margin-left: 40px;
  }

  section.portfolio-header {
    row-gap: 16px;
  }

  #hello .content-text {
    grid-column: 1 / 7;
  }

  .column-last {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    align-self: start;
  }

  .column-first {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  .column-second {
    grid-column: 4 / 7;
    grid-row: 2 / 3;
  }

  #contacts .social {
    grid-column: 1 / 7;
    grid-row: 2 / 3;
    align-self: center;
  }

  #contacts .content-text {
    grid-column: 1 / 7;
    grid-row: 3 / 4;
  }
}

